import {
  getCareTypes,
  getPackageCategory,
  getPackageDetails,
  getPackageList,
  getProductCategories,
  getProducts,
} from '../../actions/catalogs'

const {createSlice} = require('@reduxjs/toolkit')

const initialState = {
  packageList: [],
  customList: [],
  details: {},

  products: [],
  careTypes: [],
  productCategories: [],
  careCategories: [],
  packageCategory: [],
  isLoading: false,
}

const catalogs = createSlice({
  name: 'catalogs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPackageList?.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(getPackageList?.fulfilled, (state, action) => {
      state.isLoading = false
      state.total_pages = action?.payload?.data?.pagination?.total_pages
      state.packageList = action?.payload.data?.packages || []
      console.log('bdvbvjb', state.total_pages)
      // state.customList = action?.payload?.custom_packages;
    })
    builder.addCase(getPackageList?.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(getPackageDetails?.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(getPackageDetails?.fulfilled, (state, action) => {
      state.isLoading = false
      state.details = action?.payload
      // state.customList = action?.payload?.custom_packages;
    })
    builder.addCase(getPackageDetails?.rejected, (state, action) => {
      state.isLoading = false
    })

    builder.addCase(getCareTypes?.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(getCareTypes?.fulfilled, (state, action) => {
      state.isLoading = false
      state.careTypes = action?.payload
      // state.customList = action?.payload?.custom_packages;
    })
    builder.addCase(getCareTypes?.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(getPackageCategory?.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(getPackageCategory?.fulfilled, (state, action) => {
      state.isLoading = false
      state.packageCategory = action?.payload.data || []
      // state.customList = action?.payload?.custom_packages;
    })
    builder.addCase(getPackageCategory?.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(getProducts?.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(getProducts?.fulfilled, (state, action) => {
      console.log(action?.payload?.data.map((item) => item?.products)?.flat(), 'dsihiw')
      state.isLoading = false
      state.total_pages = action?.payload?.total_pages
      state.products = action?.payload?.data?.map((item) => item?.products)?.flat() || []
    })
    builder.addCase(getProducts?.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(getProductCategories?.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getProductCategories?.fulfilled, (state, action) => {
      state.isLoading = false
      state.productCategories = action?.payload
      // state.customList = action?.payload?.custom_packages;
    })
    builder.addCase(getProductCategories?.rejected, (state, action) => {
      state.isLoading = false
    })
  },
})

export default catalogs.reducer
