import {createAsyncThunk} from '@reduxjs/toolkit'
import {CREATE_ORDER, ORDERS, SHOP_ORDERS} from '../../../axios/services'
import {API_ERROR} from '../../../global'
import {toast as message} from 'react-toastify'
import { getApi, patchApi} from '../../../axios'
import {PARTNERS} from '../../../axios/services'

export const getPartners = createAsyncThunk('oms/getPartners', async (payload = '', thunkAPI) => {
  try {
    console.log(payload, 'sdjkvhls')
    const response = await getApi(
      `${PARTNERS}?order_id=${payload?.id || ''}&page=${
        payload?.page || 1
      }&page_per_items=10&created_on=${payload?.created_at || ''}&location=${
        payload?.location || ''
      }&is_available=${payload?.is_available || ''}`
    )
    if (response?.data?.success) {
      return response?.data?.data
    }
  } catch (error) {
    message.error(error?.response?.data?.message || API_ERROR)
    return thunkAPI.rejectWithValue(error.response.data.message)
  }
})

export const getOrders = createAsyncThunk('oms/getOrders', async (payload, thunkAPI) => {
  try {
    const response = await getApi(
      `${ORDERS}?sort_by=${payload?.sortBy || 'all'}&created=${payload?.created || ''}&scheduled=${
        payload?.scheduled || ''
      }&state=${payload?.state || ''}&page=${payload?.page || 1}&user_role=${
        payload?.user_role || ''
      }&items_per_page=10`
    )
    console.log(response, 'Full API response =======================')

    if (response?.data?.success) {
      const orders = response?.data?.data?.orders || []
      const pagination = response?.data?.data?.pagination || {}
      
      console.log(pagination, orders,'Orders List =======================')
      return { orders, pagination }
    }
  } catch (error) {
    message.error(error?.response?.data?.message || API_ERROR)
    return thunkAPI.rejectWithValue(error.response.data.message)
  }
})

export const getOrderById = createAsyncThunk('oms/getOrderById', async (payload = '', thunkAPI) => {
  try {
    const response = await getApi(CREATE_ORDER + '?order_id=' + payload)
    if (response?.data?.success) {
      return response?.data?.data
    }
  } catch (error) {
    message.error(error?.response?.data?.message || API_ERROR)
    return thunkAPI.rejectWithValue(error.response.data.message)
  }
})

export const patchOrder = createAsyncThunk('oms/patchOrder', async ({body, next}, thunkAPI) => {
  try {
    const response = await patchApi(CREATE_ORDER, body)
    if (response?.data?.success) {
      next()
      message.success(response?.data?.message)
    }
  } catch (error) {
    message.error(error?.response?.data?.message || API_ERROR)
    return thunkAPI.rejectWithValue(error.response.data.message)
  }
})
export const getShopOrders = createAsyncThunk(
  'oms/getShopOrders',
  async (payload = '', thunkAPI) => {
    try {
      const response = await getApi(`${SHOP_ORDERS}`)
      if (response?.data?.success) {
        return response?.data
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)
