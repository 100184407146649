/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import clsx from 'clsx'
import {Dispatch, FC, SetStateAction} from 'react'
import {KTIcon} from '../../../helpers'
import {Link} from 'react-router-dom'

const tabs: ReadonlyArray<{link: string; icon: string; tooltip: string}> = [
  {
    link: 'oms',
    icon: 'shield-tick',
    tooltip: 'Order Management System',
  },
  {
    link: 'hpms',
    icon: 'element-11',
    tooltip: 'Healthcare Professional Management System',
  },
  {
    link: 'hppms',
    icon: 'element-10',
    tooltip: 'Healthcare Partner Professional Management System',
  },
  

  {
    link: 'catalogs',
    icon: 'chart-simple',
    tooltip: 'Catalogs',
  },
  {
    link: 'users',
    icon: 'abstract-26',
    tooltip: 'Users',
  },
  {
    link: 'cms',
    icon: 'element-9',
    tooltip: 'Customer Management System',
  },
  {
    link: 'employers',
    icon: 'abstract',
    tooltip: 'Employers',
  },

  {
    link: 'vpms',
    icon: 'external-drive',
    tooltip: 'Vendor Partner Management System',
  },
  // {
  //   link: 'marketing',
  //   icon: 'add-files',
  //   tooltip: 'Marketing',
  // },
]

type Props = {
  link: string
  setLink: Dispatch<SetStateAction<string>>
}


const getMaxHeight = (): string => {
  if (window.innerWidth <= 576) {
    return '85vh'  
  } else if (window.innerWidth <= 768) {
    return '75vh'  // Tablets
  } else {
    return '65vh'  
  }
}


const AsideTabs: FC<Props> = ({link, setLink}) => (
  <div
    className='hover-scroll-y mb-4'
    style={{
      maxHeight: getMaxHeight(),  
      overflowY: 'auto',          
    }}
  >
    {/* begin::Nav */}
    <ul className='nav flex-column gap-2' id='kt_aside_nav_tabs'>
      {/* begin::Nav item */}
      {tabs.map((t) => (
        <li key={t.link} className='d-flex justify-center flex-column align-items-center '>
          {/* begin::Nav link */}
          <Link
            className={clsx(
              'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light',
              {active: link && link?.includes(t?.link)}
            )}
            to={t.link}
            onClick={() => setLink(t.link)}
          >
            <KTIcon iconName={t.icon} className='fs-2x' />
          </Link>
          <p
            className={clsx(
              'custom-list-title text-active-primary text-gray-800 fw-bold text-uppercase',
              {
                active: link && link?.includes(t?.link),
              }
            )}
          >
            <span className='d-inline-block' data-toggle='tooltip' title={t?.tooltip}>
              {t?.link}
            </span>
          </p>
          {/* end::Nav link */}
        </li>
      ))}
      {/* end::Nav link */}
    </ul>
    {/* end::Tabs */}
  </div>
)

export {AsideTabs}
